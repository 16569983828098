
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require( './bootstrap' );

jQuery(function( $ ) {
    "use strict"

    // Mobile Menu
    webfitters.mobileMenu( '#mobile-menu', '.mobile-menu-button', '#mobile-menu-screen' );

    // Search
    webfitters.showElementOnClick( '#header .search-svg', '#header .search' );
    $( '#header .search-svg' ).on( 'click', function() {
        $( '#header .search-input' ).focus();
    } );

    // Main Menu
    var menuDropdown = function( selector ) {
        $( selector ).hover(
            function() {
                var dropdown = $( this ).find( '.sub-menu' ),
                    catHover = new Popper ( $( this ), dropdown, {
                    placement: 'bottom-start'
                });

                $( this ).addClass( 'active' );
                $( this ).find( '.sub-menu' ).stop().slideDown( 300 );

                catHover.scheduleUpdate();
            },
            function() {
                var dropdown = $( this ).find( '.sub-menu' ),
                    catHover = new Popper ( $( this ), dropdown, {
                    placement: 'bottom-start'
                });

                $( this ).removeClass( 'active' );
                $( this ).find( '.sub-menu' ).stop().slideUp( 200 );

                catHover.scheduleUpdate();
            }
        )
    }
    menuDropdown( '.header__bottom #menu-primary-navigation > li.menu-item-has-children' );
    menuDropdown( '.header__contact .header__login' );

    // Interior Hero Ken Burns
    if ( $( '#interior-hero' ).length ) {
        $( '#interior-hero' ).addClass( 'loaded' );
    }

});
